<template>
  <base-section id="menu" space="0">
    <v-container class="px-8 py-12">
      <div v-if="currentProduct">
        <!-- Esta es la pestaña que se abre cuando le haces click a un producto -->
        <v-slide-x-transition hide-on-leave>
          <!-- Cartel de unidades -->
          <base-outlined-block>
            <v-col class="font-weight-bold text-subtitile-1 pl-0" cols="7">
              <div>Unidades</div>
              <div
                v-if="
                  $store.state.currentProduct.quantity >=
                  getStockQuantity(currentProduct.stock)
                "
                class="text-subtitle-2 font-weight-light without-stock"
              >
                Stock máximo
              </div>
            </v-col>
            <v-col cols="5" class="secondary rounded-xl px-5 chip">
              <v-row justify="space-between" align="center" class="py-2">
                <!-- Boton para disminuir -->
                <v-btn
                  :disabled="$store.state.currentProduct.quantity <= 1"
                  @click="
                    $store.commit(
                      'currentProduct/SET_CURRENT_PRODUCT_QUANTITY',
                      --$store.state.currentProduct.quantity
                    )
                  "
                  icon
                  fab
                  x-small
                  elevation="0"
                >
                  <v-icon color="black">mdi-minus</v-icon>
                </v-btn>
                <!-- Cantidad -->
                <div
                  class="px-1 text-h6"
                  :class="
                    $store.state.currentProduct.quantity <= 0
                      ? 'grey--text lighten-2 font-weight-regular'
                      : 'font-weight-medium'
                  "
                >
                  {{ $store.state.currentProduct.quantity }}
                </div>
                <!-- Boton para aumentar -->
                <v-btn
                  :disabled="
                    Boolean(
                      $store.state.currentProduct &&
                        $store.state.currentProduct.options[0]
                    ) ||
                    $store.state.currentProduct.quantity >=
                      getStockQuantity(currentProduct.stock) ||
                    getMinimumFlavorLimit > 0
                  "
                  color="black"
                  @click="
                    $store.commit(
                      'currentProduct/SET_CURRENT_PRODUCT_QUANTITY',
                      ++$store.state.currentProduct.quantity
                    )
                  "
                  icon
                  fab
                  x-small
                  elevation="0"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </base-outlined-block>
        </v-slide-x-transition>
        <!-- Si el producto tiene gustos o adicionales -->
        <div v-if="currentProduct && currentProduct.optionsGroups">
          <!-- Recorro las categorias que tenga ese producto, puede ser Adicional o Gustos -->
          <v-slide-x-transition
            v-for="(optionGroup, index) in currentProduct.optionsGroups"
            hide-on-leave
            :key="index"
          >
            <base-outlined-block>
              <v-col class="font-weight-bold text-subtitile-1 pl-0" cols="7">
                <div>{{ optionGroup.name }}</div>
                <!-- Si tiene una cantidad minima que debe ingresar aparece como obligatorio -->
                <div class="font-weight-regular">
                  {{
                    getMinimumFlavorLimit > 0 ? "(Obligatorio)" : "(Opcional)"
                  }}
                </div>
              </v-col>
              <v-col class="pa-0" cols="5">
                <!-- Boton para elegir los adicionales -->
                <!-- Si tengo elegido mas de un producto tengo que deshabilitarlo porque no puedo ponerle adicionales o gustos -->
                <!-- A su vez si la categoria es adicional y ninguna de sus opciones tiene stock lo tengo que deshabilitar-->
                <v-btn
                  :disabled="
                    $store.state.currentProduct.quantity !== 1 ||
                    everyAdditionalHasNegativeStock(optionGroup)
                  "
                  @click="
                    $router.push({
                      name: 'ProductExtras',
                      params: {
                        ...$route.params,
                        optionsGroupsIndex: index,
                      },
                      query: $route.query,
                    })
                  "
                  v-ripple
                  elevation="0"
                  block
                  height="48px"
                  class="secondary rounded-xl px-6"
                >
                  <div
                    class="text-center font-weight-bold text-subtitile-1 black--text"
                  >
                    {{ getBtnText(optionGroup) }}
                  </div>
                </v-btn>
              </v-col>
              <v-col class="pa-0" cols="12">
                <ul
                  v-for="(option, index) in getOptionsFromCurrentProduct.filter(
                    (x) => x.optionsGroupsName === optionGroup.name
                  )"
                  :key="index"
                >
                  <li>{{ showOptionLine(option) }}</li>
                </ul>
              </v-col>
            </base-outlined-block>
          </v-slide-x-transition>
        </div>
        <!-- Avisos por si no esta dentro del limite de gustos -->
        <div
          v-if="optionalLeft > 0 && getCurrentProductQuantity < 2"
          class="text-start error--text"
        >
          Selecciona al menos {{ optionalLeft }} {{ getOptionText }} para
          continuar
        </div>
      </div>

      <base-section v-else space="0">
        <v-row v-if="loadingContent" justify="center" class="py-12">
          <base-progress-circular />
        </v-row>
        <v-row v-else class="pt-12" justify="center">
          <v-col cols="10" class="text-center">
            <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
              mdi-cart-off
            </v-icon>
          </v-col>

          <v-col cols="10">
            <base-subheading
              class="text-center text--disabled"
              title="No se encontró ningún producto!"
            />
          </v-col>
        </v-row>
      </base-section>
    </v-container>
  </base-section>
</template>

<script>
import getCurrentProduct from "@/mixins/getCurrentProduct";
import stock from "@/mixins/stock";
import { formatPrice, currencySymbol } from "@/utils/price.js";
export default {
  mixins: [getCurrentProduct, stock],
  data() {
    return {
      //Me dice los adicionales o gustos que faltan
      optionalLeft: 0,
    };
  },
  mounted() {
    this.upsertStockToCurrentProduct();
  },
  computed: {
    originName() {
      return this.$store.getters["menu/originName"];
    },
    loadingContent() {
      return this.$store.state.menu.loading;
    },
    getOptionsFromCurrentProduct() {
      return this.$store.state.currentProduct.options;
    },
    getMinimumFlavorLimit() {
      //Devuelvo el limite inferior de los gustos o adicionales, lo uso para saber si tengo que poner obligatorio u opcional en la etiqueta
      return this.currentProduct?.minimumFlavorLimit ?? 0;
    },
    getOptionText() {
      //Me devuelve la palabra adicionales o gustos en singular o plural dependiendo la cantidad de opciones que haya que a;adir
      if (this.hasAdditionalsOptions() && this.hasFlavorOptions())
        return this.optionalLeft > 1
          ? "gustos o adicionales"
          : "gusto o adicional";
      else if (this.hasAdditionalsOptions())
        return this.optionalLeft > 1 ? "adicionales" : "adicional";
      else if (this.hasFlavorOptions())
        return this.optionalLeft > 1 ? "gustos" : "gusto";

      return "";
    },
    getCurrentProductQuantity() {
      return this.$store.state.currentProduct.quantity;
    },
  },
  methods: {
    formatPrice,
    currencySymbol,
    showOptionLine(option) {
      let result = `${option.quantity}x - ${option.name}`;

      if (option.unitPrice != 0) {
        const price = option.unitPrice * option.quantity;
        result += ` ${currencySymbol()} ${formatPrice(price, 2)}`;
      }
      return result;
    },
    upsertStockToCurrentProduct() {
      //Esta funcion se fija el producto en el que estoy parado y le agrega el stock a los adicionales
      if (this.currentProduct && this.currentProduct.optionsGroups) {
        //Si tiene option groups
        this.currentProduct.optionsGroups.forEach((optionGroup) => {
          //Me fijo el optionGroup que sea adicional
          if (optionGroup.name == "Adicionales") {
            var listOfStock = this.$store.state.menu.profile?.listOfStocks; //Me traigo la lista de stock que pedi del back
            optionGroup.options.forEach((option) => {
              //Me fijo por cada opcion
              var result = listOfStock?.find((item) => item.sku === option.sku);
              if (result == null)
                //si no estaba le pongo null para saber diferenciarlo de cuando haya stock = 0
                option.stock = null;
              //Si la opcion que analizo esta en la lista de stock le pongo el stock
              else option.stock = result.stock;
            });
          }
        });
      }
    },
    everyAdditionalHasNegativeStock(optionGroup) {
      // Esta funcion me devuelve true si todas las opciones de ese optionGroup tienen stock negativo o 0
      if (optionGroup.name == "Adicionales" && this.isStockValidationEnabled) {
        var everyOptionHasNegativeStock = true;
        optionGroup.options.forEach((option) => {
          if (
            option.stock > 0 ||
            option.stock == null ||
            option.stock == undefined
          )
            everyOptionHasNegativeStock = false;
        });
        return everyOptionHasNegativeStock;
      } else return false;
    },
    getBtnText(optionGroup) {
      var text = "Elegir";
      if (optionGroup.name == "Adicionales") {
        var result = this.everyAdditionalHasNegativeStock(optionGroup);
        if (result == true) text = "SIN STOCK";
      }
      return text;
    },
    updateProductOptionsQty() {
      //Cuando se hace un cambio en el currentAddedProduct ejecuto esta funcion que me calcula los adicionales o gustos restantes que me faltan para alcanzar el limite inferior
      var product = this.$store.state.currentProduct;
      if (this.currentProduct?.hasToEnableFlavorLimit) {
        //Solamente si tiene limite el producto quiero hacer el calculo
        //Inicializo en la cantidad minima que deben tener
        this.optionalLeft = this.currentProduct.minimumFlavorLimit;
        //Me fijo todas las opciones que tiene ese producto
        if (product.options.length > 0) {
          product.options.forEach((option) => {
            if (
              (option.optionsGroupsName === "Adicionales" ||
                option.optionsGroupsName === "Gustos") &&
              this.optionalLeft > 0 //Si la opcion es un adicional o gusto le resto la cantidad que haya de ese adicional
            ) {
              this.optionalLeft -= option.quantity;
              if (this.optionalLeft < 0) this.optionalLeft = 0; // lo pongo en 0 para que asi no me muestre el cartel
            }
          });
        }
        //Me fijo si el producto tiene adicionales o gustos para elegir, ya que en el caso de que no tenga, la validacion la haria igual y esta mal, solo la hago si tiene adicionales disponibles para elegir
        this.optionalLeft =
          this.hasAdditionalsOptions() || this.hasFlavorOptions()
            ? this.optionalLeft
            : 0;
      }
    },
  },
  watch: {
    currentAddedProduct: {
      handler() {
        this.updateProductOptionsQty(); //Llamo a la funcion que me edita las cantidades restantes de adicionales o gustos
        if (this.hasAddedProduct) {
          this.initializeAddedProduct(
            this.$store.state.currentProduct.shouldResetOptionals
          );
          return;
        }

        if (this.$store.state.currentProduct.shouldResetOptionals) {
          this.$store.commit(
            "currentProduct/SET_CURRENT_PRODUCT_OPTIONS",
            this.currentAddedProduct?.options
              ? this.currentAddedProduct.options
              : []
          );
        }
        this.$store.commit("currentProduct/SET_SHOULD_RESET_OPTIONALS", true);
      },
      immediate: true,
    },
    currentProduct: {
      handler() {
        if (this.currentProduct != undefined)
          this.upsertStockToCurrentProduct();
      },
    },
  },
};
</script>
<style scoped>
.chip {
  max-height: 4rem;
}
.pointer {
  cursor: pointer;
}
.without-stock {
  color: rgb(255, 0, 0);
}
</style>
